@import '../../../base/assets/scss/_base/config';

.m-gsu_promo {
  text-align: left;
  color: $batcave;
  font-size: 14px;
  border-bottom: 1px solid $dirty_seagull;
  padding-bottom: 20px;

  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--title{
      font-family: $font-family-poppins;
      font-size: 1.142em;
      line-height: .88;
      padding: 2px 0 0 8px;
      font-weight: 900;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      border-left: 16px solid $accent_yellow;
    }

    &--link{
      font-weight: 600;
      font-size: 0.875em;
      line-height: 1;
      color: $dory;
      text-decoration: none;
      display: flex;
      align-items: center;

      span{
        margin-left: 0.25em;
      }

      svg{
        margin-left: 8px;
        fill: $dory;
        height: 8px;
      }

      &:hover{
        color: $dory-hover;

        svg{
          fill: $dory-hover;
        }
      }
    }
  }

  &__body{
    display: block;
    text-decoration: none;
    &--container{
      display: flex;
      flex-direction: column;
    }
    &:hover{
      .m-gsu_promo__btn{
        color: $white;
        background-color: $dory;
      }
    }
  }

  &__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    color: $batcave;
    padding-top: 12px;

    &--text{
      padding-right: 20px;
    }

    &--title{
      font-family: $font-family-poppins;
      font-weight: 700;
      font-size: 1.285em;
      line-height: 1.1;
      margin: 0 0 8px 0;
    }

  }

  &__img{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--outer{
      width: 100%;
    }
    &--container{
      border-radius: 8px;
      overflow: hidden;
      height: 0;
      position: relative;
      width: 100%;
      padding-top: 56.25%;
    }
  }

  &__btn{
    font-family: $font-family-poppins;
    border: 1px solid $dory;
    color: $dory;
    font-size: 1em;
    line-height: 1.142;
    border-radius: 24px;
    font-weight: 600;
    padding: 12px 21px;
    white-space: nowrap;
  }
}

@media screen and (min-width: 1024px) {
  .m-gsu_promo {
    font-size: 16px;
    padding-bottom: 30px;

    &__body{
      &--container{
        flex-direction: row;
      }
    }

    &__content{
      flex: 1 1 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;

      &--text{
        padding: 0 45px 32px 0;

        display: flex;
        flex-direction: column;
      }

      &--title{
        font-size: 1.75em;
        margin: 0;
      }

    }

    &__img{
      &--outer{
        flex: 1 1 100%;
        order:1;
      }
    }
  }
}
