@import '../../../base/assets/scss/_base/config';

@mixin title-font-large {
  font-size: 20px;
  line-height: 1.6;
  font-weight: 800;
  letter-spacing: -0.6px;
}

@mixin title-font-medium {
  font-size: 16px;
  line-height: 2;
  font-weight: 700;
  letter-spacing: -0.6px;
}

.m-taboola{
  text-align: left;
  &__title{
    font-family: $font-family-poppins;
    align-items: center;
    position: relative;
    white-space: nowrap;
    display: flex;
    margin-bottom: 24px;
    @include title-font-large;
    &--bluesky{
      .m-taboola__title--text{
        padding-left: 12px;
      }
      &:before{
        content: '';
        height: 100%;
        background-color: $pineapple;
        width: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 2px;
      }
    }
    &--text{
      margin: 0;
      padding: 0 12px 0 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
    }
    &--link{
      display: block;
      text-decoration: none;
      cursor: pointer;
      height: 16px;
      width: 16px;

      &:hover{
        .m-taboola__title--icon{
          filter: sepia(1) saturate(100%) hue-rotate(90deg);
          transform: rotate(180deg);
        }
      }

    }
    &--icon{
      transition: all .3s ease-in-out;
      width: auto;
      height: 100%;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .m-taboola{

    &__title{
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, s)) {
  .m-taboola{

    &__title{
      margin-bottom: 28px;
      @include title-font-medium;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, l)) {
  .m-taboola{

    &__title{
      @include title-font-large;
    }
  }
}

@media print {
  .m-taboola{
    display: none;
  }
}
