@import "../../../base/assets/scss/_base/config";

.m-ap-wrapper.stream-container {
  .m-ap {
    &__author-info {
      display: flex;

      .m-ap {
        &__image-container {
          position: relative;
          width: 112px;
          height: 112px;
          border-radius: 50%;
          background-color: $pineapple;
          margin-right: 40px;
          overflow: hidden;
          .m-ap__image {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }

        &__name-container {
          display: flex;
          flex-direction: column;

          .m-ap {
            &__name-title {
              position: relative;
              color: $black;
              font-family: $font-family-poppins;
              font-size: 28px;
              font-style: normal;
              font-weight: 800;
              line-height: 28px;
              text-align: start;
              margin-bottom: 8px;

              &.lborder {
                padding-left: 16px;

                &::before {
                  content: "";
                  height: 56px;
                  background-color: $pineapple;
                  width: 8px;
                  display: block;
                  position: absolute;
                  left: 0;
                  top: 0;
                  border-radius: 8px;
                  margin-right: 8px;
                }
              }
            }

            &__position-name {
              color: $pigeon;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              text-align: start;
              margin: 0;

              &.lpad {
                padding-left: 16px;
              }
            }
          }
        }
      }
    }
    &__author-bio {
      padding-top: 40px;
      display: inline-flex;
      .description {
        color: $pigeon;
        font-family: $font-family-sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        text-align: start;
        padding-top: 0;

        .more-text.hidden {
          display: none;
        }

        .read-more {
          color: $picasso;
          cursor: pointer;

          &.hidden {
            display: none;
          }

          &-link {
            text-decoration: none;
            color: #0047ff ;
            border: none;
            background: none;
          }
        }
      }
    }

    hr {
      height: 1px;
      background: $owl;
      opacity: 0.3;
      margin-top: 28px;
      margin-bottom: 48px;
    }
  }

  .stream-list-parent {
    
    .stream-row.stream-row-stream_item {
      &:first-of-type {
        margin: 0;
      }
      .vertical-stream-item {
        display: flex;
        max-height: none;
        margin-bottom: 36px;

        .article-info {
          @include xy-cell-size($size: 8 of 12, $breakpoint: xxs);
          padding-left: 0;
          .title {
            color: $batcave;
            font-family: $font-family-poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }

          .excerpt {
            max-height: none;
            line-height: 18px;
          }
        }

        .image-container {
          order: 2;
          @include xy-cell-size($size: 4 of 12, $breakpoint: xxs);
          display: inline-flex;
          a {
            position: relative;
            padding-top: 100%;
            height: 100%;
            width: 100%;

            img.vertical-stream-img {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 12px;
            }
          }
        }
        &::after {
          content: none;
        }
      }

      &:nth-of-type(7) {
        margin-top: 25px;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .m-ap-wrapper.stream-container {
    .stream-list-parent {
      .stream-row {
        .vertical-stream-item {
          .image-container {
            a {
              padding-top: 56.56%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, s)) {
  .m-ap-wrapper.stream-container {
    .stream-list-parent {
      .stream-row.stream-row-stream_item {
        .vertical-stream-item {
          .article-info {
            @include xy-cell-size($size: 4 of 7, $breakpoint: s);
            .title {
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
              margin-bottom: 13px;
            }

            .excerpt {
              color: $batcave;
              font-family: $font-family-sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
          }

          .image-container {
            @include xy-cell-size($size: 3 of 7, $breakpoint: s);
            height: auto;
            a {
              padding-top: 56.56%;
              width: 100%;
              height: 100%;
              flex-shrink: 0;

              img.vertical-stream-img {
                border-radius: 16px;
              }
            }
          }
        }

        &:nth-of-type(7) {
          margin-top: 32px;
        }
      }
      .m-ap {
        &__author-info {
          .m-ap {
            &__image-container {
              height: 180px;
              width: 180px;
            }
            &__name-container {
              .m-ap {
                &__name-title {
                  font-size: 36px;
                  line-height: 40px;
                  letter-spacing: -0.5px;
                  margin-bottom: 16px;

                  &.lborder {
                    padding-left: 22px;
                    &::before {
                      width: 12px;
                      height: 88px;
                    }
                  }
                }
                &__position-name {
                  font-size: 16px;
                  line-height: 24px;

                  &.lpad {
                    padding-left: 10px;
                  }
                }
              }
            }
          }
        }
        &__author-bio {
          .description {
            @include xy-cell-size($size: 6 of 7, $breakpoint: s);
            font-size: 18px;
            line-height: 32px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, m)) {
  .m-ap-wrapper.stream-container {
    .stream-list-parent {
      .stream-row.stream-row-stream_item {
        .vertical-stream-item {
          .article-info {
            @include xy-cell-size($size: 4 of 7, $breakpoint: m);
          }
          .image-container {
            @include xy-cell-size($size: 3 of 7, $breakpoint: m);
            a {
              flex-shrink: 0;
              padding-top: 56.66%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .m-ap-wrapper.stream-container {
    .stream-list-parent {
      .stream-row.stream-row-stream_item {
        .vertical-stream-item {
          margin-bottom: 52px;

          .article-info {
            @include xy-cell-size($size: 4 of 7, $breakpoint: xl);
          }
        }
      }
    }
  }
}
