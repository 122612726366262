@import '../../../base/assets/scss/_base/config';

.m-newsletter {
  text-align: left;
  margin-bottom: 40px !important;
  padding-bottom: 40px;

  &__inner{
    display: flex;
    padding: 36px 28px 28px 28px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 20px;
    background: $white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.10), 0 0 1px 0 rgba(0, 0, 0, 0.10);
  }

  &--signed-up{
    padding: 0;
    margin: 0!important;
  }

  &__title{
    color: $batcave;
    font-family: $font-family-poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 16px 0;
  }

  &__desc{
    color: $black;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 24px 0;
  }

  &__message {
    display: none;
    padding: 16px 20px;
    border-radius: 12px;
    width: 100%;
    font-weight: 600;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    line-height: 20px;
    justify-content: left;
    align-items: center;
    margin-top: 20px;

    p{
      margin: 0;
      font: inherit;
    }
    svg{
      flex: 0 0 20px;
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }

    &--heading{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      h4{
        margin: 0;
        font-family: $font-family-poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
      }
    }
    &--success {
      .m-newsletter--success & {
        display: block;
      }
      margin-top: 0;
      background: $guac;
      color: $avocado;
      svg{
        fill: $avocado;
      }
    }

    &--error {
      .m-newsletter--error & {
        display: flex;
      }
      background: $salsa;
      color: $tomato;

      svg{
        align-self: baseline;
        fill: $tomato;
      }
    }
  }

  &__form{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    .m-newsletter--success &{
      display: none;
    }

    &--email{
      flex: 1 0 100%;
      width: 100%;
      padding: 11px 20px;
      border-radius: 30px;
      border: 1px solid $pebble;
      background: $white;
      color: $batcave;
      font-family: $font-family-poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 20px;

      &::placeholder{
        color: $charcoal;
      }

      &:user-invalid {
        border-color: $solo_cup;
      }

    }

    &--submit{
      flex-grow: 0;
      padding: 11px 20px;
      border-radius: 48px;
      border: 1px solid $picasso;
      background: $white;
      color: $picasso;
      font-family: $font-family-poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      &:hover{
        background: $picasso;
        color: $white;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, 'm')) {
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }
}
