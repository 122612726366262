@import '../../../base/assets/scss/_base/config';

$ad-lbl-font-size: 13px;
$ad-lbl-line-height: 1.35;
$ad-lbl-height: $ad-lbl-font-size * $ad-lbl-line-height;
$ad-stack-gap: 12px;
$ad-stack-single-ad-height: 600px;
$ad-stack-double-ad-height: 500px + $ad-stack-gap;

.m-gam{
  margin: auto;

  &__placeholder{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dirty_seagull;
    margin: 0 auto;

    p{
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  &__ad-lbl{
    margin: 0 auto;
    padding: 0;
    text-align: right;
    font-size: $ad-lbl-font-size;
    line-height: $ad-lbl-line-height;
    font-family: $font-family-base;
    color: $dolphin;
  }

  &--loaded{
    // This centers loaded mweb ads, allowing for e2e
    .smartphone &{
      margin: 0 -10px;
    }
    .smartphone .stream-container &{
      margin: 0 -25px;
    }
    .smartphone .caas-body &{
      margin: 0 -20px;
    }
  }

  &__container{
    // AD stack styling
    &--stacked{
      height: $ad-stack-single-ad-height;
      overflow: hidden;

      // Ad stack styling for ABP
      &.m-gam__container--abp{
        height: $ad-stack-single-ad-height + $ad-lbl-height;
      }

      // Ad stack styling if 2 300x250 ads load
      .mpu--stack &.m-gam__container--top_right_stack,
      .btf--stack &.m-gam__container--mid_right_stack {
        height: $ad-stack-double-ad-height;
        &.m-gam__container--abp{
          height: $ad-stack-double-ad-height + ($ad-lbl-height * 2);
        }
      }

      // Styling for gap between ads
      .m-gam--stack-top{
        margin-bottom: $ad-stack-gap;
        &.uba-container{
          margin-bottom: 0;
          + .m-gam__ad-lbl{
            margin-bottom: $ad-stack-gap;
          }
        }
      }

    }
  }
}
