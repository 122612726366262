@import '../../../base/assets/scss/_base/config';

.m-yss_vs--olympic {
  display: none;

  &.show {
    display: block;
  }
}

.m-yss_vs--o {
  min-height: 300px;

  &-table {
    width: 100%;
  }

  &-th,
  &-td {
    max-width: 40px;
    min-width: 40px;
    width: 40px;

    &.country {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
    }
    &.total {
      max-width: 65px;
      min-width: 65px;
      width: 65px;
    }
    &.bronze {
      border-right: 1px solid $dirty_seagull;
    }
  }

  &-h-row {
    font-size: 11px;
    line-height: 12px;
    font-family: $font-family-poppins;
    font-weight: 600;

    .m-yss_vs--o {
      &-th,
      &-td {
        color: $battleship;
  
        &.country {
          color: $pigeon;
        }
  
        &.total {
          font-weight: 700;
          color: $pigeon;
        }
      }
    }
  }

  &-row {
    font-size: 13px;
    line-height: 24px;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    color: $batcave;

    .m-yss_vs--o {
      &-th,
      &-td {
        line-height: 16px;
        font-family: $font-family-sans-serif;
        font-weight: 500;
  
        &.country {
          font-size: 13px;
          font-weight: 600;
          font-family: $font-family-poppins;
        }
  
        &.total {
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          font-family: $font-family-poppins;
        }
      }
    }
  }

  &-h-cell,
  &-cell {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 16px;

    &.country {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-right: 0px;
    }
    &.total {
      justify-content: center;
      margin-right: 0px;
    }
  }

  &-h-cell {
    padding: 0px 0px 20px 0px;

    &-gold-medal {
      background-color: #FFCB00;
    }

    &-silver-medal {
      background-color: #A9B1B9;
    }

    &-bronze-medal {
      background-color: #CF7F27;
    }

    &-gold,
    &-silver,
    &-bronze {
      &-medal {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    &-m-gold,
    &-m-silver,
    &-m-bronze {
      display: flex;
    }
  }

  &-cell {
    padding: 0px 0px 20px 0px;
    &-last{
      padding: 0px;
    }

    &.country {
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }

    &-country {
      &-name {
        word-break: break-all;
        display: none;
      }

      &-code {
        display: flex;
      }

      @media screen and (min-width: 400px) and (max-width: 601px) {
        &-name {
          display: flex;
        }

        &-code {
          display: none;
        }
      }

      @media screen and (min-width: 1650px) {
        &-name {
          display: flex;
        }

        &-code {
          display: none;
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    line-height: 12px;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    color: $backwater;
    position: relative;
    top: -4px;
    margin-top: 26px;

    &.invisible {
      display: none;
    }

    &-main {
      display: flex;

      span {
        display: block;
        padding-bottom: 16px;
      }

      svg path {
        fill: $backwater;
      }
    }
  }

  &-link {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: $picasso;

      svg path {
        fill: $picasso;
      }
    }
  }
}