@import '../../../base/assets/scss/_base/config';

.m-priceline-travel-search {
  font-family: $font-family-sans-serif;
  width: 100%;

  h3 {
    color: $batcave;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    margin: 0;
    margin-top: 15px;
    padding-left: 15px;
    padding-top: 15px;
    text-align: left;

    @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .wrapper {
    display: table;
    padding: 15px;
    width: 100%;

    .image {
      display: table-cell;
      height: 242px;
      padding-right: 20px;
      vertical-align: middle;
      width: 57%;

      @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
        display: table-header-group;
        width: 100%;
      }

      @media (min-width: 601px) and (max-width: 752px) {
        width: 50%;
      }

      @media (min-width: 900px) and (max-width: 1230px) {
        width: 50%;
      }

      a {
        color: $white;
        cursor: pointer;
        display: block;
        height: 242px;
        text-decoration: none;

        @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
          height: 272px;
        }

        div {
          height: 100%;
          position: relative;
          width: 100%;

          img {
            background-position: center;
            background-size: cover;
            height: 100%;
            vertical-align: top;
            width: 100%;
          }

          .overlay {
            background: $featured-video-background;
            bottom: 0;
            filter: opacity(0.5);
            height: 100%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            transition-delay: 0s;
            transition-duration: 0.2s;
            transition-property: opacity;
            transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            width: 100%;
            z-index: 2;
          }

          .overlay-text {
            filter: opacity(1);
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 25px;
            margin-left: 10px;
            margin-top: -30px;
            position: absolute;
            text-align: left;
            transition-delay: 0s;
            transition-duration: 0.2s;
            transition-property: opacity;
            transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            z-index: 2;
          }
        }
      }
    }

    .content {
      display: table-cell;
      padding: 0 10px 0 20px;
      position: relative;
      text-align: left;
      vertical-align: middle;
      width: 43%;
      z-index: 2;

      @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
        display: table-footer-group;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }

      @media (min-width: 601px) and (max-width: 753px) {
        width: 50%;
      }

      @media (min-width: 900px) and (max-width: 1230px) {
        width: 50%;
      }

      .travel-options {
        background-color: $marshmallow;
        height: 100%;
        margin-bottom: 18px;
        padding-top: 10px;
        width: 100%;

        @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
          padding-left: 10px;
          padding-right: 10px;
        }

        a {
          color: $batcave;
          display: inline-block;
          height: 50%;
          text-align: center;
          text-decoration: none;
          width: 50%;

          @media screen and (min-width: 753px) and (max-width: 900px),
            screen and (max-width: 600px) {
            padding-left: 10px;
            padding-right: 10px;
          }

          &:hover {
            color: $dory-hover;

            div {
              div {
                &.Hotels {
                  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22.92' height='27.791' viewBox='-492.854 79.056 22.92 27.791'%3e%3cg fill='none' stroke='%230f69ff' stroke-width='1.2' stroke-linejoin='round' stroke-miterlimit='10'%3e%3cpath d='M-489.4519958496 82.820999145508h3.546000003815v3.351000070572h-3.546000003815zm6.2850036621 0h3.546000003815v3.351000070572h-3.546000003815zm6.324981689453 0h3.545000076294v3.351000070572h-3.545000076294z'/%3e%3cuse xlink:href='%23B'/%3e%3cuse xlink:href='%23B' x='6.28500366211'/%3e%3cpath d='M-476.842010498047 88.360000610352h3.545000076294v3.351999998093h-3.545000076294z'/%3e%3cuse xlink:href='%23B' y='5.539001464843'/%3e%3cuse xlink:href='%23B' x='6.28500366211' y='5.539001464843'/%3e%3cpath d='M-476.842010498047 93.899002075195h3.545000076294v3.351999998093h-3.545000076294z'/%3e%3cpath d='M-492.253 79.656v26.59h7.648v-3.876c0-1.434 1.24-2.606 2.758-2.606h.946c1.518 0 2.758 1.173 2.758 2.606v3.876h7.61v-26.59h-21.72z'/%3e%3c/g%3e%3cdefs %3e%3cpath id='B' d='M-489.45199584961 88.360000610352h3.546000003815v3.351999998093h-3.546000003815z'/%3e%3c/defs%3e%3c/svg%3e");
                }

                &.Flights {
                  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='45.64400100708' height='19.805999755859' viewBox='-456.169 83.751 45.644 19.806'%3e%3cpath fill='none' stroke='%230f69ff' stroke-width='1.2' stroke-linejoin='round' stroke-miterlimit='10' d='M-436.89 92.843l-5.222-7.105c3.666-1.375 3.964-1.672 5.15-1.133 1.894.86 8.692 5.004 11.034 4.125l10.214-3.832c4.377-1.643 6.62 4.022 2.14 5.703l-32.07 12.03c-4.48 1.682-7.854-3.55-9.926-6.952l3.295-1.245c1.387-.52 3.605 2.827 6.715 1.66l8.67-3.252z'/%3e%3c/svg%3e");
                }

                &.car-rentals {
                  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='32.526000976563' height='23.176000595093' viewBox='-394.694 77.846 32.526 23.176' fill='none' stroke='%230f69ff' stroke-width='1.2' stroke-linejoin='round'%3e%3cg stroke-miterlimit='10'%3e%3cpath d='M-377.92 96.427h13.73c.782 0 1.422-.64 1.422-1.423V90.98c0-.783-.547-1.754-1.215-2.16l-.906-.55c-.67-.406-1.545-1.287-1.95-1.958l-4-6.646c-.403-.67-1.373-1.22-2.155-1.22h-5.437m1.297 17.98h-15.54c-.782 0-1.422-.64-1.422-1.423V90.98c0-.783.547-1.754 1.215-2.16l.906-.55c.668-.406 1.545-1.287 1.948-1.958l4-6.646c.403-.67 1.374-1.22 2.155-1.22h5.437'/%3e%3cpath d='M-378.43 80.595h-3.447c-.782 0-1.752.55-2.156 1.22l-1.943 3.228c-.404.67-.094 1.218.688 1.218h6.858m0-5.666h3.447c.782 0 1.752.55 2.156 1.22l1.943 3.228c.404.67.094 1.218-.688 1.218h-6.858'/%3e%3c/g%3e%3ccircle cx='-386.711' cy='91.803' r='1.915'/%3e%3ccircle cx='-370.152' cy='91.803' r='1.915'/%3e%3cg stroke-miterlimit='10'%3e%3cpath d='M-384.6 96.427V99c0 .782-.64 1.422-1.422 1.422h-1.357c-.782 0-1.422-.64-1.422-1.422v-2.07m20.75-.503V99c0 .782-.64 1.422-1.422 1.422h-1.357c-.782 0-1.422-.64-1.422-1.422v-2.07m53.24.524c0 .515-.422.936-.937.936h-25.678c-.516 0-.937-.42-.937-.936V81.2c0-.515.42-.937.937-.937h25.678c.515 0 .937.42.937.937v16.245z'/%3e%3cpath d='M-337.382 80.272V77.42c0-.516.42-.937.936-.937h7.32c.515 0 .936.42.936.937v2.853m-10.55-.001V98.4m11.907-18.128V98.4'/%3e%3c/g%3e%3c/svg%3e");
                }

                &.Vacations {
                  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='28.750999450684' height='23.107000350952'  viewBox='-347.162 75.883 28.751 23.107' fill='none' stroke='%230f69ff' stroke-width='1.2' stroke-linejoin='round' stroke-miterlimit='10'%3e%3cpath d='M-319.01 97.454c0 .515-.422.936-.937.936h-25.678c-.516 0-.937-.42-.937-.936V81.21c0-.515.42-.937.937-.937h25.678c.515 0 .937.42.937.937v16.245z'/%3e%3cpath d='M-337.382 80.272V77.42c0-.516.42-.937.936-.937h7.32c.515 0 .936.42.936.937v2.853m-10.55-.001V98.4m11.907-18.128V98.4'/%3e%3c/svg%3e");
                }
              }
            }
          }

          div {
            margin-bottom: 5px;
            margin-top: 5px;

            div {
              display: inline;

              &.Hotels {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22.92' height='27.791' viewBox='-492.854 79.056 22.92 27.791'%3e%3cg fill='none' stroke='%236e7780' stroke-width='1.2' stroke-linejoin='round' stroke-miterlimit='10'%3e%3cpath d='M-489.4519958496 82.820999145508h3.546000003815v3.351000070572h-3.546000003815zm6.2850036621 0h3.546000003815v3.351000070572h-3.546000003815zm6.324981689453 0h3.545000076294v3.351000070572h-3.545000076294z'/%3e%3cuse xlink:href='%23B'/%3e%3cuse xlink:href='%23B' x='6.28500366211'/%3e%3cpath d='M-476.842010498047 88.360000610352h3.545000076294v3.351999998093h-3.545000076294z'/%3e%3cuse xlink:href='%23B' y='5.539001464843'/%3e%3cuse xlink:href='%23B' x='6.28500366211' y='5.539001464843'/%3e%3cpath d='M-476.842010498047 93.899002075195h3.545000076294v3.351999998093h-3.545000076294z'/%3e%3cpath d='M-492.253 79.656v26.59h7.648v-3.876c0-1.434 1.24-2.606 2.758-2.606h.946c1.518 0 2.758 1.173 2.758 2.606v3.876h7.61v-26.59h-21.72z'/%3e%3c/g%3e%3cdefs %3e%3cpath id='B' d='M-489.45199584961 88.360000610352h3.546000003815v3.351999998093h-3.546000003815z'/%3e%3c/defs%3e%3c/svg%3e");
              }

              &.Flights {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='45.64400100708' height='19.805999755859' viewBox='-456.169 83.751 45.644 19.806'%3e%3cpath fill='none' stroke='%236e7780' stroke-width='1.2' stroke-linejoin='round' stroke-miterlimit='10' d='M-436.89 92.843l-5.222-7.105c3.666-1.375 3.964-1.672 5.15-1.133 1.894.86 8.692 5.004 11.034 4.125l10.214-3.832c4.377-1.643 6.62 4.022 2.14 5.703l-32.07 12.03c-4.48 1.682-7.854-3.55-9.926-6.952l3.295-1.245c1.387-.52 3.605 2.827 6.715 1.66l8.67-3.252z'/%3e%3c/svg%3e");
              }

              &.car-rentals {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='32.526000976563' height='23.176000595093' viewBox='-394.694 77.846 32.526 23.176' fill='none' stroke='%236e7780' stroke-width='1.2' stroke-linejoin='round'%3e%3cg stroke-miterlimit='10'%3e%3cpath d='M-377.92 96.427h13.73c.782 0 1.422-.64 1.422-1.423V90.98c0-.783-.547-1.754-1.215-2.16l-.906-.55c-.67-.406-1.545-1.287-1.95-1.958l-4-6.646c-.403-.67-1.373-1.22-2.155-1.22h-5.437m1.297 17.98h-15.54c-.782 0-1.422-.64-1.422-1.423V90.98c0-.783.547-1.754 1.215-2.16l.906-.55c.668-.406 1.545-1.287 1.948-1.958l4-6.646c.403-.67 1.374-1.22 2.155-1.22h5.437'/%3e%3cpath d='M-378.43 80.595h-3.447c-.782 0-1.752.55-2.156 1.22l-1.943 3.228c-.404.67-.094 1.218.688 1.218h6.858m0-5.666h3.447c.782 0 1.752.55 2.156 1.22l1.943 3.228c.404.67.094 1.218-.688 1.218h-6.858'/%3e%3c/g%3e%3ccircle cx='-386.711' cy='91.803' r='1.915'/%3e%3ccircle cx='-370.152' cy='91.803' r='1.915'/%3e%3cg stroke-miterlimit='10'%3e%3cpath d='M-384.6 96.427V99c0 .782-.64 1.422-1.422 1.422h-1.357c-.782 0-1.422-.64-1.422-1.422v-2.07m20.75-.503V99c0 .782-.64 1.422-1.422 1.422h-1.357c-.782 0-1.422-.64-1.422-1.422v-2.07m53.24.524c0 .515-.422.936-.937.936h-25.678c-.516 0-.937-.42-.937-.936V81.2c0-.515.42-.937.937-.937h25.678c.515 0 .937.42.937.937v16.245z'/%3e%3cpath d='M-337.382 80.272V77.42c0-.516.42-.937.936-.937h7.32c.515 0 .936.42.936.937v2.853m-10.55-.001V98.4m11.907-18.128V98.4'/%3e%3c/g%3e%3c/svg%3e");
              }

              &.Vacations {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='28.750999450684' height='23.107000350952'  viewBox='-347.162 75.883 28.751 23.107' fill='none' stroke='%236e7780' stroke-width='1.2' stroke-linejoin='round' stroke-miterlimit='10'%3e%3cpath d='M-319.01 97.454c0 .515-.422.936-.937.936h-25.678c-.516 0-.937-.42-.937-.936V81.21c0-.515.42-.937.937-.937h25.678c.515 0 .937.42.937.937v16.245z'/%3e%3cpath d='M-337.382 80.272V77.42c0-.516.42-.937.936-.937h7.32c.515 0 .936.42.936.937v2.853m-10.55-.001V98.4m11.907-18.128V98.4'/%3e%3c/svg%3e");
              }
            }

            span {
              display: block;
              margin-top: 2.5px;
            }
          }
        }
      }

      .book-btn {
        @media screen and (min-width: 753px) and (max-width: 900px), screen and (max-width: 600px) {
          padding-left: 10px;
          padding-right: 10px;
        }

        a {
          display: block;
          font-weight: bold;
          text-decoration: none;

          div {
            color: $white;
            display: inline-block;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1.2px;
            line-height: 12px;
            margin: 0;
            padding: 15px 30px 12px;
            text-align: center;
            text-transform: uppercase;
            top: 15px;
            width: 100%;
            background-color: $dory;

            &:hover{
              background-color: $dory-hover;
            }
          }
        }
      }
    }
  }
}
