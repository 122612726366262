@import '../../../base/assets/scss/_base/config';

.m-promo-standard-stream {
  background-size: cover !important;
  font-family: $font-family-poppins;
  position: relative; 
  height: 380px;

  .img-wrap {
    overflow: hidden;
    padding-top: 50%;

    width: auto;
    height: 215px;

    a {
      height: 100%;
      width: 100%;

      img {
        display: block;
        width: 100%;
        border-radius: 16px 16px 0 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 215px;
        object-fit: cover;
      }
      &::after {
        content: '';
        display: block;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.65%, #000 98.05%);
        position: relative;
        margin-top: 175px;
        height: 40px; 
      }
    }
  }

  &__link {
    bottom: 0;
    left: 0;
    position: absolute;
    text-decoration: none;
    right: 0;
  }
  &__text {
    color: #fff;
    background: #000;
    text-align: left;
    text-decoration: none;
    padding: 1px 24px 16px 24px;
    border-radius: 0 0 16px 16px;
    position: absolute;
    bottom: 0px;
    height: 165px;
    width: 100%;

    h2 {
      font-size: 22px;
      font-weight: 900;
      font-family: $font-family-poppins;
      font-style: normal;
      letter-spacing: -0.48px;
      text-transform: uppercase;
      height: 17px;
      line-height: 20px;
    }
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      font-family: $font-family-sans-serif;
      margin-bottom: 16px;
    }
  }
  .btn {
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 10px 16px 10px 16px;
    border: 1px solid $white;    
    border-radius: 48px;
    font-family: $font-family-poppins;
    height: 36px;
    align-items: center;
    text-decoration: none;
  }
}
