@import '../../../base/assets/scss/_common/sidebar-news-feeds';
@import '../../../base/assets/scss/_base/config';

.m-eventcoverage {
  border: 1px solid $dirty_seagull;

  a {
    color: black;
    text-decoration: none;

    .heading-layout {
      line-height: 0;
      margin: 15px 0 15px auto;
      text-align: center;

      img {
        height: 15px;
        max-width: 270px;
        vertical-align: top;
      }

      h3 {
        color: $batcave;
        font-family: $font-family-sans-serif;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.235;
        padding-left: 20px;
        text-align: left;
      }
    }

    .title-image {
      height: 0;
      margin-bottom: 25px;
      position: relative;
      background: #f6f7f8;
      background: linear-gradient(to right, #eee 5%, #ddd 20%, #eee 35%);

      img {
        height: 100%;
        height: auto;
        left: 0;
        min-height: 1px;
        position: absolute;
        top: 0;
        vertical-align: top;
        width: 100%;
      }
    }
  }
  &__title, &__2024-label {
    text-align: left;
    font-size: 20px;
    line-height: 16px;
    letter-spacing: -1px;
    color: $batcave;
    text-transform: capitalize;
    font-family: $font-family-poppins;
  }

  &__2024-label {
    color: $picasso;
  }
  &__24-label {
    font-size: 20px;
    color: $red;
  }

  ul {
    padding: 0;

    li {
      border-color: transparent;
      list-style-type: none;
      margin-bottom: 10px;
      padding: 0 15px 0 30px;
      position: relative;
      text-align: left;

      a {
        color: black;
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.286;
        text-decoration: none;
      }

      a:hover {
        color: $dory;
      }
    }

    li::before {
      border-bottom-width: 4.5px;
      border-color: transparent;
      border-left-color: $dirty_seagull;
      border-left-width: 9px;
      border-right-width: 0;
      border-style: solid;
      border-top-width: 4.5px;
      content: '';
      display: block;
      left: 15px;
      position: absolute;
      top: 7px;
    }
  }

  &__readmore {
    padding-bottom: 25px;
    padding-left: 30px;
    text-align: left;

    &-link {
      font-weight: 700;
      font-size: 14px;
      text-decoration: none;
    }
  }

  &__readmore a.m-eventcoverage__readmore-link {
    color: #0f69ff;

    &:hover {
      color: $cobalt;
    }
  }
}
@media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
  .ec_politics, .ec_elections, .ec_personal_finance, .ec_tournaments, .ec_nfl_draft, .ec_editorial_pick, .ec_event_coverage_v2 {
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    .heading {
      h3 {
        text-align: start;
        color: $batcave;
        font-family: $font-family-poppins;
        font-size: 20px!important;
        font-style: normal;
        font-weight: 800;
        line-height: 24px!important;
        letter-spacing: -0.6px!important;
        margin-bottom: 0px;
        margin-top: 0px
      }
    }
    .m-cb__content--right {
      margin-bottom: 30px;
      .m-cb {
        &__content--item {
          margin: -7px -11px 0px 0px;
        }
        &__link {
          margin-bottom: 24px;
          display: inline;
  
          .provider-logo {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 8px;
            padding-top: 16px;        
  
            &__img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              object-fit: cover;
  
              &--container {
                position: relative;
                width: 16px;
                height: 16px;
                margin-right: 8px;
              }
            }
            &__name {
              font-family: $font-family-sans-serif;
              margin: 0;
              padding: 0;
              color: $backwater;
              font-size: 11px;
              text-decoration: none;
              line-height: 14px;
              font-weight: 400;
            }
  
          }
        }
        &__cta--container{
          display:none;
        }
        &__heading {
          font-size: 14px;
          font-family: $font-family-poppins;
          line-height: 20px;
          font-style: normal;
          font-weight: 700;
          color: $batcave;
          text-align: left;
          margin-bottom: 0px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
        }
        &__image {
          position: relative;
          height: 0;
          padding-top: 56.25%;
          border-radius: 12px!important;
          overflow: hidden;
          margin-bottom: 0;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .m-ec {
      &__mweb_cta {
        display: flex!important;
        justify-content: flex-end;
      }
      &__cta_link {
        text-decoration: none;
        color: $picasso!important;
        border: none;
        background-color: transparent;
        margin: -25px -10px -24px 0;
        padding: 0px;
      }
      &__cta_text {
        font-family: $font-family-sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
        letter-spacing: -0.42px;
      }
    }
  }
  .ec {
    &__carousel {
      &--container{
        position: relative;
      }
  
      scrollbar-width: none; // Firefox Hide scrollbar
      -ms-overflow-style: none; // IE Hide scrollbar
      &::-webkit-scrollbar { // Webkit Hide scrollbar
        display: none;
      }
  
      overflow-y: scroll;
  
      .grid-x {
        flex-wrap: nowrap;
      }
  
      & &--item-round {
        width: calc(25% - 1.25rem);
      }
    }
  }
}

.ec_politics, .ec_personal_finance, .ec_elections, .ec_tournaments, .ec_nfl_draft, .ec_editorial_pick, .ec_event_coverage_v2 {
  .heading {
    h3 {
      text-align: start;
      color: $batcave;
      font-family: $font-family-poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 32px;
      letter-spacing: -0.54px;
    }
  }
  .m-ec__mweb_cta {
    display: none;
  }
  .m-cb__content{

    &--right{
      .m-cb__content--item:nth-child(n+3){
        .m-cb__link {
          margin-bottom: 40px;
        }
      }
      .m-cb{
        &__link {

          margin-bottom: 36px;
          text-decoration: none;

          .provider-logo {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 8px;

            &__img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              object-fit: cover;

              &--container {
                position: relative;
                width: 16px;
                height: 16px;
                margin-right: 8px;
              }
            }

            &__name {
              font-family: $font-family-sans-serif;
              margin: 0;
              padding: 0;
              color: $backwater;
              font-size: 11px;
              text-decoration: none;
              line-height: 14px;
              font-weight: 400;
            }
          }
        }

        &__heading {
          font-size: 14px;
          font-family: $font-family-poppins;
          line-height: 20px;
          font-style: normal;
          font-weight: 700;
          color: $batcave;
          text-align: left;
        }

        &__image {
          position: relative;
          height: 0;
          padding-top: 56.25%;
          border-radius: 16px;
          overflow: hidden;
          margin-bottom: 0;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &__cta {
          margin-bottom: 0;
          padding: 0;
          font-size: 13px;
          line-height: 16px;
          border: none;
          background-color: transparent;
          font-family: $font-family-poppins;
          &--container{
            margin-bottom: 0;
            justify-content: start;
          }
          &:hover {
            color: $picasso;
            background-color: transparent;
          }
        }
      }
    }
  }

  .m-ec__cta_link {
    color: $batcave;

    &_main {
      display: inline-flex;
      font-weight: 600;
      font-size: 12px;
      font-family: $font-family-sans-serif;
    }

    &_right_icon {
      margin-left: 4px;
      position: relative;
      top: 1px;

      svg {
        width: 10px;
        height: 10px;

        path {
          fill: $batcave;
        }
      }
    }

    &:hover {
      color: $picasso;

      .m-ec__cta_link_right_icon svg path {
        fill: $picasso;
      }
    }
  }
}

@media screen and (max-width: (map-get($breakpoints, s) - 1px)) {
  .ec_politics, .ec_personal_finance, .ec_elections, .ec_tournaments, .ec_nfl_draft, .ec_editorial_pick, .ec_event_coverage_v2 {
    .m-cb__content{

      &--right{
        .m-cb__content--item:nth-child(n+3){
          .m-cb__link {
            margin-bottom: 28px;
          }
        }

        .m-cb{

          &__link {
            flex-direction: row-reverse;
            margin-bottom: 18px;
          }

          &__heading {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
          }

          &__image {
            max-width: none!important;
            padding-top: 56.25%!important;
            border-radius: 8px;

          }
          &__cta {
            margin-bottom: 0;
            padding: 0;
            font-size: 12px;
            line-height: 16px;
            border: none;
            background-color: transparent;
            font-family: $font-family-sans-serif;
            font-weight: 600;
            color: $batcave;
            &--container{
              margin-bottom: 0;
              justify-content: start;
            }
            &:hover {
              color: $picasso;
              background-color: transparent;
    
            }
          }
        }
      }
    }
  }
}
// breakpoint, where it becomes right rail
@media screen and (min-width: 754px) {
  .ec_politics, .ec_elections, .ec_personal_finance, .ec_tournaments, .ec_nfl_draft, .ec_editorial_pick, .ec_event_coverage_v2 {
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    .m-cb__content{
      &--right{
        .m-cb__content--item:nth-child(n+3){
          .m-cb__link {
            margin-bottom: 28px;
          }
        }

        .m-cb{
          
          &__link {
            flex-direction: row-reverse;
            margin-bottom: 18px;
          }
          &__heading {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
          }
          &__image {
            padding-top: 100%;
            border-radius: 8px;
          }
          &__cta {
            margin-bottom: 0;
            padding: 0;
            font-size: 12px;
            line-height: 16px;
            border: none;
            font-family: $font-family-sans-serif;
            background-color: transparent;
            color: $batcave;
            font-weight: 600;
            &--container{
              margin-bottom: 0;
              justify-content: start;
            }
            &:hover {
              color: $picasso;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1124px) {
  .ec_politics, .ec_elections, .ec_personal_finance, .ec_tournaments, .ec_nfl_draft, .ec_editorial_pick, .ec_event_coverage_v2 {
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    .m-cb__content {
      &--right {
        .m-cb {
          &__image {
            width: 80px;
            height: 80px;
          }
          &__link {
            .m-cb__image--container {
              margin-right: 14px;
            }
            .provider_logo--container {
              margin-right: 5px;

              .m-cb__heading {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// Override for mail us
.m-event-coverage--mailToday {
  .heading h3 {
    @include variableColor(--text-base, $batcave);
  }

  .m-cb__content--right {
    .m-cb__link .provider-logo__name {
      @include variableColor(--text-base, $backwater);
    }

    .m-cb__heading {
      @include variableColor(--text-base, $batcave);
    }
  }

  &.ec_politics,
  &.ec_elections,
  &.ec_personal_finance,
  &.ec_tournaments,
  &.ec_nfl_draft,
  &.ec_editorial_pick,
  &.ec_event_coverage_v2 {
    .m-ec__cta_link {
      @include variableColor(--text-base, $batcave);

      &_right_icon {
        svg path {
          @include variableFill(--text-base, $batcave);
        }
      }
    }
  }
}
.just_the_facts_rr {
  .m-cb__content--right {
    margin-bottom: -20px;
    @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
      margin-bottom: 0px;
    }
  }
}