@import "../../../base/assets/scss/_base/config";

.m-trivia-games {

  &__rr {
    display: flex;
    font-family: $font-family-poppins;
    align-items: flex-start;

    &--heading {
      font-size: 18px;
      margin-bottom: 14px;
      margin-top: 0;
      text-align: left;
      color: $batcave;
    }

    &--badge {
      padding: 3px 6px;
      border-radius: 10px;
      background-color: $bonsai;
      color: $white;
      line-height: 1;
      font-size: 12px;
      font-weight: 700;
      margin-top: 5px;
      margin-left: 10px;

    }
  }

  .widgetWrapper {
    position: relative;
    width: 100%;
    padding-top: 390px;
    overflow: visible;
  
    iframe {
      background-color: white;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      box-shadow:
        0px 0px 16px rgba(0, 0, 0, 0.05),
        0px 16px 20px rgba(199, 205, 210, 0.4);
      border-radius: 24px;
      height: 390px;
    }
  }
  
  @media screen and (min-width: map-get($breakpoints, xs)) {
    .widgetWrapper {
      padding-top: 240px;
      iframe {
        min-height: fit-content;
        height: 246px;
      }
    }
  }
  
  @media screen and (min-width: map-get($breakpoints, s)) {
    .widgetWrapper {
      padding-top: 370px;
      iframe {
        height: 400px;
      }
    }
  }
  
  @media screen and (min-width: map-get($breakpoints, m)) {
    .widgetWrapper {
      iframe {
        height: 420px;
      }
    }
  }
  
  @media screen and (min-width: map-get($breakpoints, l)) {
    .widgetWrapper {
      padding-top: 400px;
      iframe {
        height: 415px;
      }
    }
  }

  // right rail

  @media only screen and (min-width: 901px) {
    .widgetWrapper.rr {
      padding-top: 390px;
      iframe {
        height: 410px;
      }
    }
  }
  
} 

.m-trivia-games.games {

  @media only screen and (min-width: 754px) {
    .widgetWrapper.rr {
      padding-top: 390px;
      iframe {
        height: 410px;
      }
    }
  }

}

